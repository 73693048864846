import React from "react";

import UI from "../images/service-icons/ui.svg";
import Motion from "../images/service-icons/motion.svg";
import Brand from "../images/service-icons/brand.svg";
import Button from "./button";

const Services = () => {
  return (
    <div id="#services">
      <div className="md:container md:mx-auto">
        <div className="py-20 pt-5 pb-12">
          <div className="flex flex-col relative bg-secondary-100 lg:py-0 py-10">
            <div className="absolute lg:block hidden h-16 bg-white bottom-0 left-0 right-0"></div>
            <div className="absolute lg:block hidden h-16 bg-white top-0 left-0 right-0"></div>
            <div className="grid lg:grid-cols-12 grid-cols-1 xl:gap-8 items-center  relative">
              <div className="lg:col-span-5 flex flex-col items-start xl:px-18 lg:px-10 md:px-8 px-5 lg:py-32 md:pb-4 pb-10">
                <h2 className="lg:text-display-lg text-display-md font-semibold pb-4">
                  What I do for my customers
                </h2>
                <p className="text-body-md font-normal text-neutral-600 pb-8">
                  As a freelance web developer, I specialize in creating dynamic
                  and engaging websites that effectively communicate my clients'
                  brand identity and achieve their business goals.
                </p>
                <p className="text-body-md font-normal text-neutral-600 pb-8">
                  With a deep understanding of front-end and back-end
                  technologies, I build responsive and user-friendly websites
                  that provide seamless user experiences across different
                  devices.
                </p>
                <Button
                  label="CONNECT WITH ME"
                  link="mailto:jmarc@nightfiredigital.com"
                  size="lg"
                />
              </div>
              <div className="lg:col-span-7 flex md:flex-row flex-col lg:gap-8 gap-6 items-center xl:pr-16 lg:pr-10 lg:pl-0 md:px-8 px-5 relative">
                <div className="flex flex-col basis-1/2 lg:gap-8 gap-6 relative">
                  <div className="bg-white p-8 shadow-xl">
                    <img src={UI} alt="UI" className="pb-8" />
                    <p className="text-display-xs font-semibold pb-4">
                      Web Development
                    </p>
                    <p className="text-body-md font-normal text-neutral-600">
                      From customizing WordPress themes to developing full-stack
                      web applications, I bring expertise in HTML, CSS,
                      JavaScript, React, PHP, and other relevant technologies.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col basis-1/2 lg:gap-8 gap-6 relative">
                  <div className="bg-white p-8 shadow-xl">
                    <img src={Motion} alt="Motion" className="pb-8" />
                    <p className="text-display-xs font-semibold pb-4">
                      Digital operations
                    </p>
                    <p className="text-body-md font-normal text-neutral-600">
                      Operate more efficiently, be data-driven, and
                      customer-centric. I will help you improve productivity,
                      cost savings, competitive advantage, and business growth.
                    </p>
                  </div>
                  <div className="bg-white p-8 shadow-xl">
                    <img src={Brand} alt="Branding" className="pb-8" />
                    <p className="text-display-xs font-semibold pb-4">
                      Design & UXO
                    </p>
                    <p className="text-body-md font-normal text-neutral-600">
                      Improve customer satisfaction, increase conversions, and
                      foster long-term customer loyalty.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
